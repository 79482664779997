import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const style = css`
    :host {
    }

    * {
        box-sizing: border-box;
    }

    .hero-slide {
        background-color: var(--sbkStageColorBgColorMuted);
        color: var(--sbkStageColorFgColorOnLightDefault);
        min-height: calc(var(--sbkStageSizingViewportSmMinHeight) - var(--sbkStageSpacingViewportSmSliderControlMarginTop) - var(--sbkStageSpacingViewportSmInsetBottom));
        margin: 0 auto;

        @media all and (min-width: ${breakpointMd}) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: var(--sbkStageSpacingViewportMdInsetTop) var(--sbkCoreGridMdMargin) 0 var(--sbkCoreGridMdMargin);
            min-height: calc(var(--sbkStageSizingViewportMdMinHeight) - var(--sbkStageSpacingViewportMdSliderControlMarginTop) - var(--sbkStageSpacingViewportMdInsetBottom));
        }
        @media all and (min-width: ${breakpointLg}) {
            padding: var(--sbkStageSpacingViewportLgInsetTop) var(--sbkCoreGridLgMargin) 0 var(--sbkCoreGridLgMargin);
            min-height: calc(var(--sbkStageSizingViewportLgMinHeight) - var(--sbkStageSpacingViewportLgSliderControlMarginTop) - var(--sbkStageSpacingViewportLgInsetBottom));
        }
        @media all and (min-width: ${breakpointXl}) {
            padding: var(--sbkStageSpacingViewportXlInsetTop) var(--sbkCoreGridXlMargin) 0 var(--sbkCoreGridXlMargin);
            min-height: calc(var(--sbkStageSizingViewportXlMinHeight) - var(--sbkStageSpacingViewportXlSliderControlMarginTop) - var(--sbkStageSpacingViewportXlInsetBottom));
            max-width: calc(var(--sbkCoreContentAreaMaxWidth) + 2 * var(--sbkCoreGridXlMargin));
        }
    }

    .hero-slide-image-container {
        @media all and (min-width: ${breakpointMd}) {
            width: calc(100% / 12 * 5);
        }
    }

    .hero-slide__content-wrapper {
        padding: var(--sbkStageSpacingViewportSmInsetTop) var(--sbkCoreGridSmMargin) 0 var(--sbkCoreGridSmMargin);
        @media all and (min-width: ${breakpointMd}) {
            padding: 0;
            width: 50%;
        }
    }

    .hero-slide__headline {
        margin: 0 0 var(--sbkStageSpacingViewportSmHeadlineMarginBottom) 0;
        font: var(--sbkStageTypographySmallScreenHeadline);
        hyphens: auto;
        @media all and (min-width: ${breakpointMd}) {
            margin: 0 0 var(--sbkStageSpacingViewportMdHeadlineMarginBottom) 0;
        }
        @media all and (min-width: ${breakpointLg}) {
            margin: var(--sbkStageSpacingViewportLgHeadlineMarginTop) 0 var(--sbkStageSpacingViewportLgHeadlineMarginBottom) 0;
            font: var(--sbkStageTypographyLargeScreenHeadline);
        }
        @media all and (min-width: ${breakpointXl}) {
            margin: var(--sbkStageSpacingViewportXlHeadlineMarginTop) 0 var(--sbkStageSpacingViewportXlHeadlineMarginBottom) 0;
        }
    }

    .hero-slide__body {
        margin: 0 0 var(--sbkStageSpacingViewportSmTextMarginBottom) 0;
        font: var(--sbkStageTypographySmallScreenBody);
        @media all and (min-width: ${breakpointMd}) {
            margin: 0 0 var(--sbkStageSpacingViewportMdTextMarginBottom) 0;
        }
        @media all and (min-width: ${breakpointLg}) {
            margin: 0 0 var(--sbkStageSpacingViewportLgTextMarginBottom) 0;
            font: var(--sbkStageTypographyLargeScreenBody);
        }
        @media all and (min-width: ${breakpointXl}) {
            margin: 0 0 var(--sbkStageSpacingViewportXlTextMarginBottom) 0;
        }
    }

    .hero-slide-image-container {
        align-content: center;
        position: relative;
    }
    
    .hero-slide__image {
        position: relative;
    }
    
    .image-award-wrapper {
        display:flex;
        gap: var(--sbkHeroTeaserSpacingSmallScrStickersGap);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        margin-left: var(--sbkCoreGridSmMargin);
        height: var(--sbkStageSizingViewportSmStickerHeight);
        @media all and (min-width: ${breakpointMd}) {
            height: var(--sbkStageSizingViewportMdStickerHeight);
        }
        @media all and (min-width: ${breakpointLg}) {
            height: var(--sbkStageSizingViewportLgStickerHeight);
        }
        @media all and (min-width: ${breakpointXl}) {
            height: var(--sbkStageSizingViewportXlStickerHeight);
        }
    }
    
    ::slotted([slot="image-award"]) {
        width: auto;
    }
`;

