import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const heroSliderStyles = css`
    :host {
        background-color: var(--sbkStageColorBgColorMuted);
    }

    .swiper {
        overflow: hidden;
        padding-bottom: var(--sbkStageSpacingViewportSmInsetBottom);
        max-width: unset;
        @media (min-width: ${breakpointMd}) {
            padding-bottom: var(--sbkStageSpacingViewportMdInsetBottom);
        }
        @media (min-width: ${breakpointLg}) {
            padding-bottom: var(--sbkStageSpacingViewportLgInsetBottom);
        }
        @media (min-width: ${breakpointXl}) {
            padding-bottom: var(--sbkStageSpacingViewportXlInsetBottom);
        }
    }
    
    .swiper-button-next {
        bottom: calc(var(--sbkStageSpacingViewportSmInsetBottom) - 14px);
        @media (min-width: ${breakpointMd}) {
            bottom: calc(var(--sbkStageSpacingViewportMdInsetBottom) - 14px);
        }
        @media (min-width: ${breakpointLg}) {
            bottom: calc(var(--sbkStageSpacingViewportLgInsetBottom) - 14px);
        }
        @media (min-width: ${breakpointXl}) {
            bottom: calc(var(--sbkStageSpacingViewportXlInsetBottom) - 14px);
        }
    }

    .swiper-button-prev {
        bottom: calc(var(--sbkStageSpacingViewportSmInsetBottom) - 14px);
        @media (min-width: ${breakpointMd}) {
            bottom: calc(var(--sbkStageSpacingViewportMdInsetBottom) - 14px);
        }
        @media (min-width: ${breakpointLg}) {
            bottom: calc(var(--sbkStageSpacingViewportLgInsetBottom) - 14px);
        }
        @media (min-width: ${breakpointXl}) {
            bottom: calc(var(--sbkStageSpacingViewportXlInsetBottom) - 14px);
        }
    }
    
`;
