import { customElement, property } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import { style } from './hero-slide.styles';

@customElement('sbk-hero-slide')
export class SbkHeroSlide extends LitElement {

  static shadowRootOptions = {...LitElement.shadowRootOptions, delegatesFocus: true};

  @property()
  heading: string = '';

  @property()
  href: string = '';

  @property({attribute: 'button-label'})
  buttonLabel: string = '';

  static get styles() {
    return style;
}

  render() {
    return html`
      <div class="hero-slide">
        <div class="hero-slide-image-container">
          <slot name="image-small"></slot>
          <div class="hero-slide__image">
            <slot name="image-large"></slot>
            <div class="image-award-wrapper">
              <slot name="image-award"></slot>
            </div>
          </div>
        </div>
        <div class="hero-slide__content-wrapper">
          ${this.renderHeadline()}
          <div class="hero-slide__body">
            <slot></slot>
          </div>
          ${this.renderLink()}
        </div>
      </div>
    `;
  }

  renderHeadline() {
    return this.heading
        ? html`<p class="hero-slide__headline">${this.heading}</p>`
        : '';
  }

  renderLink() {
    return this.href && this.buttonLabel ?
        html`<div>
          <sbk-button href=${this.href}>${this.buttonLabel}</sbk-button>
        </div>`
        : '';
  }
}