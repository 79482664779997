import {register, SwiperContainer} from 'swiper/element/bundle';
import {heroSliderStyles} from "@/components/hero-slider/hero-slider.styles.ts";
import {contentSliderStyles} from "@/components/content-slider/content-slider.styles.ts";

const SbkHeroSlider = {
    initialize() {
        register();

        const params = {
            a11y: true,
            navigation: {

            },
            watchOverflow: true,
            pagination: {
                type: 'custom',
                renderCustom: function (_swiper: SwiperContainer, current: string, total: string) {
                    if (Number(current) < 10) {
                        current = '0' + current;
                    }
                    if (Number(total) < 10) {
                        total = '0' + total;
                    }
                    return '<span><b>' + current + '</b></span><span>' + '/' + '</span><span>' + total + '</span>';
                }
            },
            injectStyles: [
                contentSliderStyles,
                heroSliderStyles,
            ],
        };

        const swiperElements: NodeListOf<SwiperContainer> = document.querySelectorAll('[variant="hero"]');
        swiperElements.forEach((swiperElement) => {
            Object.assign(swiperElement, params);
            swiperElement.initialize();
        });
    }
}

export default SbkHeroSlider